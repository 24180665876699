<template>
  <div>
    <Header title="告警列表" color="#666" />
    <!-- <div class="search-container flex-col">
      <div class="flex-grow-1 flex-row">
        <div class="flex-grow-1 item">
          <van-cell
            title="开始时间："
            :value="listQuery.start_time || '请选择时间'"
            is-link
            arrow-direction="down"
            @click="showStartTimePicker = true"
          />
        </div>
        <div class="flex-grow-1 item">
          <van-cell
            title="结束时间："
            :value="listQuery.end_time || '请选择时间'"
            is-link
            arrow-direction="down"
            @click="showEndTimePicker = true"
          />
        </div>
      </div>
      <div class="flex-grow-1 flex-row">
        <div class="flex-grow-1 item">
          <van-cell
            title="告警状态："
            :value="listQuery.status || '请选择状态'"
            is-link
            arrow-direction="down"
            @click="showStatusPicker = true"
          />
        </div>
        <div class="flex-grow-1 item">
          <van-cell
            title="告警级别："
            :value="listQuery.level || '请选择级别'"
            is-link
            arrow-direction="down"
            @click="showLevelPicker = true"
          />
        </div>
      </div>
    </div> -->
    <van-empty v-if="list.length == 0" description="暂无数据"></van-empty>
    <van-list
      class="pdb50"
      v-model="loading"
      :finished="finished"
      finished-text=""
      @load="getList"
    >
      <div class="cells" v-for="(item, index) in list" :key="index">
        <!-- <img src="../../assets/images/jiaobiao.png" alt="">
        <span class="jiaobiao">已恢复</span> -->
        <van-cell
          :border="false"
          :to="{ path: '/gaojing/detail', query: { id: item.id } }"
        >
          <template #title>
            <div class="flex-row fs14">
              <div class="flex-grow-1 fwb">
                {{ item.equipmentName }}
              </div>
              <div class="flex-grow-0">
                <van-tag :type="getBgcStatus(item.alarmStatus)">{{item.alarmStatus}}</van-tag>
                <van-tag :type="getByStatus(item.status)">{{
                  item.status
                }}</van-tag>
              </div>
            </div>
          </template>
          <template #label>
            <div class="flex-col mgt5 fs13 color666">
              <div class="flex-grow-0 flex-row">
                <div class="flex-grow-1">站点名称：{{ item.siteName }}</div>
                <div class="flex-grow-0">告警级别：{{ item.alarmLevel }}</div>
              </div>
              <div class="flex-grow-0 flex-row mgt5">
                告警名称： {{ item.title }}
              </div>
              <div class="flex-grow-0 flex-row mgt5">
                <div class="flex-grow-1">
                  告警时间：{{ parseTime(item.alarmTime) }}
                </div>
              </div>
            </div>
          </template>
        </van-cell>
      </div>
    </van-list>

    <!-- 告警状态选择 -->
    <van-popup v-model="showStatusPicker" position="bottom">
      <van-picker
        title="选择告警状态"
        show-toolbar
        :columns="statusList"
        @confirm="selectStatus"
        @cancel="showStatusPicker = false"
      />
    </van-popup>

    <!-- 告警级别选择 -->
    <van-popup v-model="showLevelPicker" position="bottom">
      <van-picker
        title="选择告警级别"
        show-toolbar
        :columns="levelList"
        @confirm="selectLevel"
        @cancel="showLevelPicker = false"
      />
    </van-popup>

    <!-- 开始时间选择 -->
    <van-popup v-model="showStartTimePicker" position="bottom">
      <van-datetime-picker
        v-model="date"
        type="date"
        title="选择年月日"
        :formatter="formatter"
        @cancel="showStartTimePicker = false"
        @confirm="onStartTimeConfirm"
      />
    </van-popup>

    <!-- 结束时间选择 -->
    <van-popup v-model="showEndTimePicker" position="bottom">
      <van-datetime-picker
        v-model="date"
        type="date"
        title="选择年月日"
        :formatter="formatter"
        @cancel="showEndTimePicker = false"
        @confirm="onEndTimeConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { getGaojingList } from "@/api/gaojing";
import { formatParams } from "@/utils/params";
import {
  Cell,
  List,
  Button,
  Toast,
  Popup,
  DatetimePicker,
  Picker,
  Tag,
  Empty,
  Badge,
} from "vant";
import Header from "@/components/header";
export default {
  name: "GeanIndex",
  components: {
    Header,
    [List.name]: List,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Toast.name]: Toast,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Empty.name]: Empty,
    [Tag.name]: Tag,
    [Badge.name]: Badge,
  },
  props: {},
  data() {
    return {
      loading: false,
      finished: false,
      listQuery: {
        pageNo: 1,
        limit: 10,
        start_time: "",
        end_time: "",
        status: "",
        level: "",
      },
      list: [],
      statusList: ["状态1", "状态2", "状态3", "状态4"],
      levelList: ["一级", "二级", "三级", "四级"],
      date: new Date(),
      showStatusPicker: false,
      showLevelPicker: false,
      showStartTimePicker: false,
      showEndTimePicker: false,
    };
  },
  watch: {},
  created() {
    this.parseTime = this.utils.parseTime;
  },
  mounted() {},
  methods: {
    getList() {
      this.loading = true;
      const params = formatParams(
        { "Q^NAME_^SL": "" },
        { page: this.listQuery.pageNo }
      );
      getGaojingList(params)
        .then((response) => {
          this.loading = false;
          let list = this.list;
          let page = parseInt(this.listQuery.pageNo);
          if (this.listQuery.pageNo === 1) {
            list = response.data.dataResult;
          } else {
            list = list.concat(response.data.dataResult);
          }
          if (
            response.data.pageResult.page ===
              response.data.pageResult.totalPages ||
            response.data.pageResult.totalCount === 0
          ) {
            this.finished = true;
          }
          this.listQuery.pageNo = page + 1;
          this.list = list;
          console.log(this.list);
        })
        .catch((err) => {
          Toast.fail(err);
        });
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    onStartTimeConfirm(e) {
      this.listQuery.start_time = this.parseTime(e, "{y}-{m}-{d}");
      this.showStartTimePicker = false;
    },
    onEndTimeConfirm(e) {
      this.listQuery.end_time = this.parseTime(e, "{y}-{m}-{d}");
      this.showEndTimePicker = false;
    },
    selectStatus(value) {
      this.listQuery.status = value;
      this.showStatusPicker = false;
    },
    selectLevel(value) {
      this.listQuery.level = value;
      this.showLevelPicker = false;
    },
    // 查询
    handleFilter() {
      this.listQuery.pageNo = 1;
      this.loading = true;
      this.finished = false;
      this.getList();
    },
    //根据类型获取状态
    getByStatus(status) {
      if (status == "已处理") {
        status = 0;
      } else if (status == "处理中") {
        status = 1;
      } else  if (status == "未处理"){
        status = 2;
      }else{
        status=3
      }
      const statusArr = ["success", "warning", "danger","primary "];
      return statusArr[status];
    },
    //根据告警状态获取tag背景颜色
    getBgcStatus(status) {
      if (status == "已恢复") {
        status = 0;
      } else {
        status = 2;
      }
      const statusArr = ["success", "warning", "danger"];
      return statusArr[status];
    },
  },
};
</script>
<style lang="scss" scoped>
.van-tag{
  margin-left: 0.5rem;
}
.cells{
position: relative;
img{
  width: 1.15rem;
  position: absolute;
  top: 0rem;
  opacity: 0.2;
  left: 0;
  z-index: 10;
}
  .jiaobiao {
    //  background-color:springgreen;
     color: white;
    //  opacity:0.5;
     font-size: 0.3rem;
     position: absolute;
     z-index: 11;
     top: 0.2rem;
     left: 0rem;
     transform: rotate(-45deg);
    //  transform: translate(10px,-10px);
   }
 .van-cell {
   margin-bottom: 0.1rem;
   margin-top: 0 !important;
  
 }
}

.search-container {
  .item {
    width: 50%;
    .van-cell__value {
      color: #1989fa;
      font-size: 12px;
    }
    .van-cell__title {
      font-size: 12px;
    }
  }
}
</style>
